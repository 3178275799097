import apiUrls from '@/setup/api-urls.json';
import CONFIG from '@/setup/js/config';

type UrlKeyValues = {
  [key: string]: string;
};

type ParamsKeyValues = {
  [key: string]: string | number;
};

/**
 * Gets the url for the given 'urlKey'. The method also accepts an Object of interpolation values.
 */
export default function getApiUrl(urlKey: keyof typeof apiUrls, values?: UrlKeyValues): string {
  let url = apiUrls[urlKey];

  if (!url) {
    throw new Error(`Unable to find an API url with the identifier ${urlKey}.`);
  }

  if (values) {
    Object.entries(values).forEach(([key, value]) => {
      url = url.replace(`{${key}}`, value);
    });
  }

  return `${CONFIG.HOCKEY_DATA_API_URL}${url}`;
}

export function getApiUrlParams(params?: ParamsKeyValues): string {
  const urlSearchParams = new URLSearchParams();

  urlSearchParams.set('apiKey', CONFIG.HOCKEY_DATA_API_KEY);
  urlSearchParams.set('league', CONFIG.HOCKEY_DATA_LEAGUE);
  urlSearchParams.set('sport', CONFIG.HOCKEY_DATA_SPORT);
  urlSearchParams.set('referer', CONFIG.HOCKEY_DATA_REFERER);

  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      urlSearchParams.set(key, String(value));
    });
  }

  return urlSearchParams.toString() ? `?${urlSearchParams.toString()}` : '';
}

export function getApiUrlWithParams(url: keyof typeof apiUrls, params?: ParamsKeyValues): string {
  return `${getApiUrl(url)}${getApiUrlParams(params)}`;
}
