import { TeamSeasonConfig } from '@/types/leauge-config';

export const Season202223: TeamSeasonConfig = {
  divisionId: 11005,
  standings: [
    {
      divisionId: 11005,
      playoffCut: 4,
    },
  ],
  playoffId: 11724,
  teamId: 0,
  seasonLabel: '2022-23',
};

export default Season202223;
