<template>
  <div :class="b()">
    <e-headline
      type="h5"
      headline="Spieler"
      :class="b('headline')"
    />
    <div :class="b('roster-content')">
      <v-data-table
        :class="b('table')"
        :headers="fieldPlayersHeaders"
        :items="fieldPlayers"
        :items-per-page="100"
        hide-default-footer
        sort-asc-icon="mdi-chevron-up"
        sort-desc-icon="mdi-chevron-down"
        items-per-page-text="pro Seite"
        multi-sort
        hover
      >
        <template #item="{ item }">
          <tr>
            <td>
              <e-player-number :jersey-number="item.playerJerseyNr" />
            </td>
            <td>
              <c-player-name
                :class="b('player')"
                :player-id="item.id"
                :first-name="item.playerLastname"
                :last-name="item.playerFirstname"
              />
              <small v-if="['F', 'C', 'LW', 'RW'].includes(item.position)">Stürmer</small>
              <small v-else>Verteidiger</small>
            </td>
            <td>{{ item.gamesPlayed }}</td>
            <td>{{ item.goals }}</td>
            <td>{{ item.assists }}</td>
            <td>{{ item.points }}</td>
            <td>{{ item.penaltyMinutes }}</td>
          </tr>
        </template>
      </v-data-table>
    </div>

    <e-headline
      type="h5"
      headline="Goalies"
      :class="b('headline')"
    />
    <div :class="b('roster-content')">
      <v-data-table
        :class="b('table')"
        :headers="goalKeepersHeaders"
        :items="goalKeepers"
        :items-per-page="100"
        hide-default-footer
        sort-asc-icon="mdi-chevron-up"
        sort-desc-icon="mdi-chevron-down"
        items-per-page-text="pro Seite"
        multi-sort
        hover
      >
        <template #item="{ item }">
          <tr>
            <td>
              <e-player-number :jersey-number="item.playerJerseyNr" />
            </td>
            <td>
              <c-player-name
                :class="b('player')"
                :player-id="item.id"
                :first-name="item.playerLastname"
                :last-name="item.playerFirstname"
              />
            </td>
            <td>{{ item.gamesPlayed }}</td>
            <td>{{ item.gamesPlayedIn }}</td>
            <td>{{ item.goalsAgainst }}</td>
            <td>{{ item.goalsAgainstAverage }}</td>
            <td>{{ item.shotsAgainst }}</td>
            <td>{{ item.savePercentage }}</td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script lang="ts">
  import { PropType, defineComponent } from 'vue';
  import cPlayerName from '@/components/c-player-name.vue';
  import eHeadline from '@/elements/e-headline.vue';
  import ePlayerNumber from '@/elements/e-player-number.vue';
  import { DataTableHeader } from '@/types/data-table-header';
  import { CombinedFieldPlayerData, CombinedGoalKeeperData } from '@/types/team-details';

  // type Setup = {}
  type Data = {
    fieldPlayersHeaders: DataTableHeader[];
    goalKeepersHeaders: DataTableHeader[];
  };

  /**
   *
   */
  export default defineComponent({
    name: 'c-team-roster-table',
    components: {
      eHeadline,
      ePlayerNumber,
      cPlayerName,
    },

    // components: {},

    props: {
      /**
       * Fieldplayers
       */
      fieldPlayers: {
        type: Array as PropType<CombinedFieldPlayerData[]>,
        default: () => [],
      },

      /**
       * Goalies
       */
      goalKeepers: {
        type: Array as PropType<CombinedGoalKeeperData[]>,
        default: () => [],
      },
    },
    // emits: [],

    // setup(): Setup {
    //   return {};
    // },
    data(): Data {
      return {
        fieldPlayersHeaders: [
          { title: '#', value: 'playerJerseyNr', width: 50 },
          { title: 'Name', value: 'playerLastname', key: 'playerLastname', minWidth: '200px' },
          { title: 'Spiele', value: 'gamesPlayed', key: 'gamesPlayed', width: 80 },
          { title: 'Tore', value: 'goals', key: 'goals', width: 80 },
          { title: 'Assits', value: 'assists', key: 'assists', width: 80 },
          { title: 'Pkt', value: 'points', key: 'points', width: 80 },
          { title: 'Strafen', value: 'penaltyMinutes', key: 'penaltyMinutes', width: 80 },
        ],
        goalKeepersHeaders: [
          { title: '#', value: 'playerJerseyNr', width: 50 },
          { title: 'Name', value: 'playerLastname', key: 'playerLastname', minWidth: '200px' },
          { title: 'S', value: 'gamesPlayed', key: 'gamesPlayed', width: 80 },
          { title: 'GPI', value: 'gamesPlayedIn', key: 'gamePlayedIn', width: 80 },
          { title: 'GA', value: 'goalsAgainst', key: 'goalsAgainst', width: 80 },
          { title: 'GAA', value: 'goalsAgainstAverage', key: 'goalsAgainstAverage', width: 80 },
          { title: 'SA', value: 'shotsAgainst', key: 'shotsAgainst', width: 80 },
          { title: 'SV%', value: 'savePercentage', key: 'savePercentage', width: 80 },
        ],
      };
    },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use 'sass:map';
  @use 'vuetify/settings' as vuetify;
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-team-roster-table {
    $this: &;

    &__headline {
      margin: 20px 0;
    }

    &__roster-content {
      margin-bottom: 50px;
    }

    &__table {
      @include mixins.dataTable();
    }
  }
</style>
