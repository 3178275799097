import { Plugin } from 'vue';
// SSR related
import lGamecenter from '@/layouts/l-gamecenter.vue';
import lTeam from '@/layouts/l-team.vue';

const plugin: Plugin = {
  install(app) {
    const components = [
      // SSR related
      lGamecenter,
      lTeam,
    ];

    components.forEach((component) => {
      app.component(component.name || '', component);
    });
  },
};

export default plugin;
