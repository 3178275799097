import debl2SeasonConfig from '@/setup/js/leagues/debl2';
import ooelSeasonConfig from '@/setup/js/leagues/oeel';
import vehl1SeasonConfig from '@/setup/js/leagues/vehl1';
import vehl2SeasonConfig from '@/setup/js/leagues/vehl2';
import vehl3SeasonConfig from '@/setup/js/leagues/vehl3';
import vehl4SeasonConfig from '@/setup/js/leagues/vehl4';
import { AppLeagueConfig } from '@/types/leauge-config';

/**
 * Define all existing seasons.
 */
const leagueConfig: AppLeagueConfig = {
  teams: [
    { ...ooelSeasonConfig },
    { ...debl2SeasonConfig },
    { ...vehl1SeasonConfig },
    { ...vehl2SeasonConfig },
    { ...vehl3SeasonConfig },
    { ...vehl4SeasonConfig },
  ],
  activeTeams: ['ÖEL', 'Damen', 'VEHL1', 'VEHL2', 'VEHL3', 'VEHL4'],
} as const;

export default leagueConfig;
