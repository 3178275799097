type AppConfig = {
  LOGO_BASE_URL_API: string;
  HOCKEY_DATA_API_URL: string;
  HOCKEY_DATA_LEAGUE: string;
  HOCKEY_DATA_SPORT: string;
  HOCKEY_DATA_REFERER: string;
  HOCKEY_DATA_API_KEY: string;
  CSS_CLASS_PREFIX: string;
  API_MOCK_DELAY: number;
  DEFAULT_LANGUAGE: string;
  DATE_FORMAT: string;
  DATE_FORMAT_FULL: string;
  DATE_FORMAT_FULL_NO_YEAR: string;
  DATE_FORMAT_SHORT: string;
  TIME_FORMAT: string;
  WP_GAME_DETAIL_PAGE_URL: string;
  URL_PARAMETERS: {
    gamecenterView: string;
    gameId: string;
    divisionId: string;
    teamId: string;
    team: string;
    season: string;
  };
  GAMECENTER_VIEWS: {
    games: string;
    standings: string;
    playoff: string;
    gameDetail: string;
  };
};

/**
 * Define all app specific config parameters.
 */
const CONFIG: AppConfig = {
  LOGO_BASE_URL_API: 'https://api.hockeydata.net/img/icehockey/ebel/team-logos',
  HOCKEY_DATA_API_URL: 'https://api.hockeydata.net/data/ebel',
  HOCKEY_DATA_LEAGUE: 'ebel',
  HOCKEY_DATA_SPORT: 'icehockey',
  HOCKEY_DATA_REFERER: 'www.h-sc.at',
  HOCKEY_DATA_API_KEY: 'e52b5722cbc89c3e51ee0cd6e2485a81',
  CSS_CLASS_PREFIX: 'naba-hdwp-',
  API_MOCK_DELAY: 0,
  DEFAULT_LANGUAGE: 'de',
  DATE_FORMAT: 'DD.MM.YYYY',
  DATE_FORMAT_FULL: 'dd, DD.MM.YYYY',
  DATE_FORMAT_FULL_NO_YEAR: 'dd, DD.MM.',
  DATE_FORMAT_SHORT: 'DD.MM.',
  TIME_FORMAT: 'HH:mm',
  WP_GAME_DETAIL_PAGE_URL: '/gamecenter/game-report/',
  URL_PARAMETERS: {
    gamecenterView: 'view',
    gameId: 'gameId',
    divisionId: 'divisionId',
    teamId: 'teamId',
    team: 'team',
    season: 'season',
  },
  GAMECENTER_VIEWS: {
    games: 'games',
    standings: 'standings',
    playoff: 'playoff',
    gameDetail: 'game-details',
  },
};

export default CONFIG;
