import { TeamSeasonConfig } from '@/types/leauge-config';

export const Season202223: TeamSeasonConfig = {
  divisionId: 11192,
  standings: [
    {
      divisionId: 11195,
    },
  ],
  playoffId: null,
  teamId: 41347,
  seasonLabel: '2022-23',
};

export default Season202223;
