import { TeamSeasonConfig } from '@/types/leauge-config';

export const Season202122: TeamSeasonConfig = {
  divisionId: 9188,
  standings: [
    {
      divisionId: 9188,
      playoffCut: 4,
    },
  ],
  playoffId: null,
  teamId: 34431,
  seasonLabel: '2021-22',
};

export default Season202122;
