import {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordName,
  RouteRecordRaw,
  createRouter,
  createWebHashHistory,
} from 'vue-router';
import leagueConfig from '@/setup/js/leagues/config';
import useSessionStore from '@/stores/session';
import {
  ATTENDANCE,
  BAD_BOYS,
  FAIRPLAY,
  GAME,
  GAMECENTER,
  GOALIES,
  PENALTYKILL,
  PLAYOFF,
  POWERPLAY,
  SCHEDULE,
  STANDINGS,
  STATS_BASE,
  TEAM,
  TOPSCORER,
} from './routes-constants';
import styleguideRoutes from './styleguide/routes';

const publicRoutes: RouteRecordRaw[] = [
  {
    name: 'gamecenter',
    path: '/gc',
    redirect: {
      name: SCHEDULE.name,
    },
    component: () => import('@/layouts/l-gamecenter.vue'),
    meta: { ...GAMECENTER.meta },
    children: [
      {
        ...SCHEDULE,
        component: () => import('@/layouts/l-schedule.vue'),
        beforeEnter: updateRouteByTeamAndSeason,
      },
      {
        ...STANDINGS,
        component: () => import('@/layouts/l-standings.vue'),
        beforeEnter: updateRouteByTeamAndSeason,
      },
      {
        ...PLAYOFF,
        component: () => import('@/layouts/l-playoff.vue'),
        beforeEnter: updateRouteByTeamAndSeason,
      },
      {
        ...STATS_BASE,
        meta: { title: 'Statistik' },
        redirect: {
          name: TOPSCORER.name,
        },
        children: [
          {
            ...TOPSCORER,
            component: () => import('@/components/c-stats-players-top-scorer.vue'),
            beforeEnter: updateRouteByTeamAndSeason,
          },
          {
            ...BAD_BOYS,
            component: () => import('@/components/c-stats-players-bad-boys.vue'),
            beforeEnter: updateRouteByTeamAndSeason,
          },
          {
            ...GOALIES,
            component: () => import('@/components/c-stats-goalkeepers.vue'),
            beforeEnter: updateRouteByTeamAndSeason,
          },
          {
            ...POWERPLAY,
            component: () => import('@/components/c-stats-team-powerplay.vue'),
            beforeEnter: updateRouteByTeamAndSeason,
          },
          {
            ...PENALTYKILL,
            component: () => import('@/components/c-stats-team-powerplay.vue'),
            beforeEnter: updateRouteByTeamAndSeason,
          },
          {
            ...FAIRPLAY,
            component: () => import('@/components/c-stats-team-fairplay.vue'),
            beforeEnter: updateRouteByTeamAndSeason,
          },
          {
            ...ATTENDANCE,
            component: () => import('@/components/c-stats-team-attendance.vue'),
            beforeEnter: updateRouteByTeamAndSeason,
          },
        ],
      },
      {
        ...GAME,
        component: () => import('@/layouts/l-game.vue'),
      },
      {
        ...TEAM,
        component: () => import('@/layouts/l-team.vue'),
      },
    ],
  },
  {
    name: 'NotFound',
    path: '/gc/:pathMatch(.*)*',
    redirect: {
      name: SCHEDULE.name,
    },
    meta: { title: 'NotFound' },
  },
];

export const router = createRouter({
  routes: import.meta.env.DEV ? publicRoutes.concat(styleguideRoutes) : publicRoutes,
  history: createWebHashHistory(),
});

export default router;

/**
 * TBD.
 *
 * @param {Object} to - The next route.
 * @param {Object} from - The last route.
 * @param {Object} next - Caller object to forward the router.
 */
function updateRouteByTeamAndSeason(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const sessionStore = useSessionStore();
  const { team, season } = to.params;

  const validTeam = leagueConfig.teams.find((item) => item.name === team);
  const validSeason = validTeam?.seasons.find((item) => item.seasonLabel === season);

  if (!validTeam) {
    to.params.team = sessionStore.activeTeam.name;
  } else {
    sessionStore.activeTeam = validTeam;
  }

  if (!validSeason) {
    to.params.season = sessionStore.activeSeason.seasonLabel;
  } else {
    sessionStore.activeSeason = validSeason;
  }

  if (!validTeam || !validSeason) {
    next({ name: to.name as RouteRecordName, params: to.params });
  } else {
    next();
  }
}
