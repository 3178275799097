export const GAMECENTER = {
  name: 'root',
  path: '/gc',
  meta: {
    title: 'Gamecenter',
  },
};
export const PLAYOFF = {
  name: 'playoff',
  path: 'playoff/:team?/:season?',
  meta: {
    title: 'Playoff',
  },
};
export const STANDINGS = {
  name: 'standings',
  path: 'standings/:team?/:season?',
  meta: {
    title: 'Tabelle',
  },
};
export const SCHEDULE = {
  name: 'schedule',
  path: 'schedule/:team?/:season?',
  meta: {
    title: 'Spiele',
  },
};
export const GAME = {
  name: 'game',
  path: 'game/:gameId?',
  meta: {
    title: 'Spiel',
  },
};
export const TEAM = {
  name: 'team',
  path: 'team/:teamId?/:divisionId?',
  meta: {
    title: 'Team',
  },
};
// Stats routes
export const STATS_BASE = {
  name: 'stats.root',
  path: 'stats/:team?/:season?',
  meta: {
    title: 'Statistik',
  },
};
export const TOPSCORER = {
  name: 'stats.top-scorer',
  path: 'top-scorer',
  meta: {
    title: 'Top Scorer',
  },
};
export const BAD_BOYS = {
  name: 'stats.bad-boys',
  path: 'bad-boys',
  meta: {
    title: 'Bad Boys',
  },
};
export const GOALIES = {
  name: 'stats.goalies',
  path: 'goalies',
  meta: {
    title: 'Goalies',
  },
};
export const POWERPLAY = {
  name: 'stats.powerplay',
  path: 'powerplay',
  meta: {
    title: 'Powerplay',
  },
};
export const PENALTYKILL = {
  name: 'stats.penaltykill',
  path: 'penaltykill',
  meta: {
    title: 'Penaltykilling',
  },
};
export const FAIRPLAY = {
  name: 'stats.fairplay',
  path: 'fairplay',
  meta: {
    title: 'Fairplay',
  },
};
export const ATTENDANCE = {
  name: 'stats.attendance',
  path: 'attendance',
  meta: {
    title: 'Zuschauer',
  },
};
