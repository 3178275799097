import { defineStore } from 'pinia';
import { Store } from '@/setup/globals';
import leagueConfig from '@/setup/js/leagues/config';
import { TeamConfig, TeamSeasonConfig } from '@/types/leauge-config';

type SessionState = {
  /**
   * Stores the theme id.
   */
  theme: string;

  activeTeam: TeamConfig;

  activeSeason: TeamSeasonConfig;
};

const storeName = Store.Session;

export default defineStore(storeName, {
  state: () => {
    const fallbackTeam = leagueConfig?.teams[0] || ({} as TeamConfig);
    const fallbackSeason = fallbackTeam?.seasons[0] || ({} as TeamSeasonConfig);

    const state: SessionState = {
      theme: 'theme-01',
      activeTeam: fallbackTeam,
      activeSeason: fallbackSeason,
    };

    return state;
  },
  getters: {
    getTeams(): TeamConfig[] {
      return leagueConfig.teams;
    },
  },
  actions: {},
});
