import { TeamSeasonConfig } from '@/types/leauge-config';

export const Season202122: TeamSeasonConfig = {
  divisionId: 9128,
  standings: [
    {
      divisionId: 9135,
      playoffCut: 3,
      name: 'Gruppe West',
    },
    {
      divisionId: 9136,
      playoffCut: 3,
      name: 'Gruppe Nord-Ost',
    },
    {
      divisionId: 9137,
      playoffCut: 2,
      name: 'Gruppe Süd',
    },
  ],
  playoffId: 9658,
  teamId: 34181,
  seasonLabel: '2021-22',
};

export default Season202122;
