import { TeamSeasonConfig } from '@/types/leauge-config';

export const Season202223: TeamSeasonConfig = {
  divisionId: 11002,
  standings: [
    {
      divisionId: 11002,
      playoffCut: 4,
    },
  ],
  playoffId: 11943,
  teamId: 731,
  seasonLabel: '2022-23',
};

export default Season202223;
