<template>
  <div :class="b()">
    <e-headline
      type="h5"
      headline="Stürmer"
      :class="b('headline')"
    />
    <v-slide-group
      :class="b('content-slider')"
      show-arrows
    >
      <c-player-card
        v-for="player in forwards"
        :key="`forward-${player.id}`"
        :fieldplayer="player"
      />
    </v-slide-group>

    <e-headline
      type="h5"
      headline="Verteidiger"
      :class="b('headline')"
    />
    <v-slide-group
      :class="b('content-slider')"
      show-arrows
    >
      <c-player-card
        v-for="player in defense"
        :key="`defense-${player.id}`"
        :fieldplayer="player"
      />
    </v-slide-group>

    <e-headline
      type="h5"
      headline="Tormänner"
      :class="b('headline')"
    />

    <v-slide-group
      :class="b('content-slider')"
      show-arrows
    >
      <c-player-card
        v-for="player in goalkeepers"
        :key="`gaolie-${player.id}`"
        :goalkeeper="player"
      />
    </v-slide-group>
  </div>
</template>

<script lang="ts">
  import { PropType, defineComponent } from 'vue';
  import cPlayerCard from '@/components/c-player-card.vue';
  import eHeadline from '@/elements/e-headline.vue';
  import { CombinedFieldPlayerData, CombinedGoalKeeperData } from '@/types/team-details';

  // type Setup = {}
  // type Data = {}

  /**
   *
   */
  export default defineComponent({
    name: 'c-team-roster-cards',
    components: {
      cPlayerCard,
      eHeadline,
    },

    props: {
      /**
       * Player
       */
      fieldplayers: {
        type: Array as PropType<CombinedFieldPlayerData[]>,
        default: () => {},
      },

      /**
       * Players
       */
      goalkeepers: {
        type: Array as PropType<CombinedGoalKeeperData[]>,
        default: () => {},
      },
    },
    // emits: [],

    // setup(): Setup {
    //   return {};
    // },
    // data(): Data {
    //   return {};
    // },

    computed: {
      forwards(): CombinedFieldPlayerData[] {
        return this.fieldplayers?.filter((player) => player.position === 'F') || [];
      },

      defense(): CombinedFieldPlayerData[] {
        return this.fieldplayers?.filter((player) => player.position === 'D') || [];
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use 'sass:map';
  @use 'vuetify/settings' as vuetify;
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-team-roster-cards {
    $this: &;

    &__content-slider {
      margin-bottom: 40px;
      position: relative;

      .v-slide-group__content {
        padding: 20px 2px;
        scroll-snap-type: x mandatory;
      }

      .v-slide-group__prev,
      .v-slide-group__next {
        position: absolute;
        height: 100%;
        background: rgba(variables.$color-grayscale--1000, 0.7);
        backdrop-filter: blur(5px);
        z-index: 9;

        @include mixins.media($down: sm) {
          min-width: 20px;
        }
      }

      .v-slide-group__prev {
        left: 0;
      }

      .v-slide-group__next {
        right: 0;
      }

      .v-slide-group__next--disabled,
      .v-slide-group__prev--disabled {
        width: 0;
        min-width: 0;
        overflow: hidden;
        display: none;
      }
    }
  }
</style>
