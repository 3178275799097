<template>
  <div :class="b()">
    <v-select
      v-model="selected"
      label="Statistik Bereich"
      :class="b('teams')"
      :items="options"
      hide-details
      density="comfortable"
      @update:model-value="onChange"
    >
      <template #item="{ props, item }">
        <v-list-subheader v-if="props.type === 'header'">
          {{ item.title }}
        </v-list-subheader>
        <v-divider
          v-else-if="props.type === 'divider'"
          class="mt-2"
        />
        <v-list-item
          v-else
          v-bind="props"
        ></v-list-item>
      </template>
    </v-select>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { ATTENDANCE, BAD_BOYS, FAIRPLAY, GOALIES, PENALTYKILL, POWERPLAY, TOPSCORER } from '@/setup/routes-constants';

  type SelectOption = {
    title?: string;
    value?: string;
    props?: {
      type: 'header' | 'divider';
    };
  };

  // type Setup = {};

  type Data = {
    selected: string;
    options: SelectOption[];
  };

  /**
   *
   */
  export default defineComponent({
    name: 'c-stats-selectors',

    // components: {},

    // props: {},
    // emits: [],

    // setup(): Setup {
    //   return {
    //   };
    // },
    data(): Data {
      return {
        selected: TOPSCORER.name,
        options: [
          { title: 'Spieler Statistik', props: { type: 'header' } },
          { title: TOPSCORER.meta.title, value: TOPSCORER.name },
          { title: BAD_BOYS.meta.title, value: BAD_BOYS.name },
          { title: GOALIES.meta.title, value: GOALIES.name },
          { props: { type: 'divider' } },
          { title: 'Team Statistik', props: { type: 'header' } },
          { title: POWERPLAY.meta.title, value: POWERPLAY.name },
          { title: PENALTYKILL.meta.title, value: PENALTYKILL.name },
          { title: FAIRPLAY.meta.title, value: FAIRPLAY.name },
          { title: ATTENDANCE.meta.title, value: ATTENDANCE.name },
        ],
      };
    },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    mounted() {
      this.selected = String(this.$route.name);
    },
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      onChange(route: string) {
        if (route && this.$route.name !== route) {
          this.$router.push({
            name: route,
          });
        }
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use 'sass:map';
  @use 'vuetify/settings' as vuetify;
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-stats-selectors {
    $this: &;
  }
</style>
