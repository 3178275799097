import { TeamConfig, TeamSeasonConfig } from '@/types/leauge-config';

const seasonsImports = import.meta.glob('./seasons/*.ts', { eager: true, import: 'default' }) as Record<
  string,
  TeamSeasonConfig
>;

const seasons = Object.values(seasonsImports)
  .map((config) => config)
  .filter((config) => !config.inactive)
  .sort((a, b) => b.seasonLabel.localeCompare(a.seasonLabel));

export const leagueNameConfig = {
  shortName: 'VEHL1',
  longName: 'VEHL1',
};

/**
 * Define all existing seasons.
 */
export const seasonConfig: TeamConfig = {
  id: 2,
  name: leagueNameConfig.shortName,
  seasons,
};

export default seasonConfig;
