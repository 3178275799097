import { TeamSeasonConfig } from '@/types/leauge-config';

export const Season202223: TeamSeasonConfig = {
  divisionId: 11058,
  standings: [
    {
      divisionId: 11061,
      playoffCut: 3,
      name: 'Gruppe West',
    },
    {
      divisionId: 11062,
      playoffCut: 3,
      name: 'Gruppe Nord-Ost',
    },
    {
      divisionId: 11063,
      playoffCut: 2,
      name: 'Gruppe Süd',
    },
  ],
  playoffId: 11852,
  teamId: 34181,
  seasonLabel: '2022-23',
};

export default Season202223;
