<template>
  <v-card :class="b('', { disabled: player.isDisabled })">
    <v-img
      v-if="showPlayerImage && !player.isDisabled"
      :class="b('image')"
      :src="player.images.playerSmallPortrait"
      cover
    />
    <div
      v-else
      :class="b('image', { team: true })"
      :style="{ backgroundImage: `url(${player.teamImage})` }"
    ></div>

    <v-card-item
      :class="b('header')"
      :title="`${player.playerFirstname} ${player.playerLastname}`"
    >
      <template #prepend>
        <e-player-number :jersey-number="player.playerJerseyNr" />
      </template>
      <template #subtitle>{{ player.nation }} - {{ player.age }} ({{ player.birthYear }})</template>
    </v-card-item>

    <div
      v-if="player.isDisabled"
      :class="b('fallback-text')"
    >
      Dieser Spieler ist spielberechtigt aber hat noch keine Spiele gespielt.
    </div>

    <v-list
      v-else
      class="bg-transparent"
    >
      <v-list-item
        v-for="(stat, index) in getFieldPlayerStats"
        :key="index"
        density="compact"
        :append-icon="stat.mvp ? 'mdi-crown-outline' : ''"
        :class="b('stat-item')"
      >
        <v-list-item-title>
          {{ stat.value }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ stat.label }}
        </v-list-item-subtitle>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script lang="ts">
  import { PropType, defineComponent } from 'vue';
  import ePlayerNumber from '@/elements/e-player-number.vue';
  import { CombinedFieldPlayerData, CombinedGoalKeeperData } from '@/types/team-details';

  type PlayerStat = {
    label: string;
    value: string | number;
    mvp: boolean;
  };

  // type Setup = {}
  // type Data = {}

  /**
   *
   */
  export default defineComponent({
    name: 'c-player-card',
    components: {
      ePlayerNumber,
    },

    // components: {},

    props: {
      /**
       * Player
       */
      fieldplayer: {
        type: Object as PropType<CombinedFieldPlayerData>,
        default: null,
      },

      /**
       * Players
       */
      goalkeeper: {
        type: Object as PropType<CombinedGoalKeeperData>,
        default: null,
      },

      /**
       * Show player image.
       */
      showPlayerImage: {
        type: Boolean,
        default: false,
      },
    },
    // emits: [],

    // setup(): Setup {
    //   return {};
    // },
    // data(): Data {
    //   return {};
    // },

    computed: {
      player(): CombinedFieldPlayerData | CombinedGoalKeeperData {
        if (this.fieldplayer) {
          return this.fieldplayer;
        }

        if (this.goalkeeper) {
          return this.goalkeeper;
        }

        return {} as CombinedFieldPlayerData;
      },

      getFieldPlayerStats(): PlayerStat[] {
        return this.player.position === 'G' ? this.getGoalieStats : this.getPlayerStats;
      },

      getPlayerStats(): PlayerStat[] {
        const player = this.player as CombinedFieldPlayerData;

        return [
          { label: 'Spiele', value: player.gamesPlayed, mvp: player.gamesPlayedMVP },
          { label: 'Tore', value: player.goals, mvp: player.goalsMVP },
          { label: 'Assits', value: player.assists, mvp: player.assistsMVP },
          { label: 'Punkte', value: player.points, mvp: player.pointsMVP },
          { label: 'Strafminuten', value: player.penaltyMinutes, mvp: player.penaltyMinutesMVP },
        ];
      },

      getGoalieStats(): PlayerStat[] {
        const player = this.player as CombinedGoalKeeperData;

        return [
          { label: 'Spiele', value: player.gamesPlayedIn, mvp: player.gamesPlayedInMVP },
          { label: 'SV% - Fangquote', value: `${player.savePercentage}%`, mvp: player.savePercentageMVP },
          { label: 'GA - Tore kassiert', value: player.goalsAgainst, mvp: player.goalsAgainstMVP },
          { label: 'GAA - Tore pro Spiel', value: player.goalsAgainstAverage, mvp: player.goalsAgainstAverageMVP },
          { label: 'SA - Geblockte Schüsse', value: player.shotsAgainst, mvp: player.shotsAgainstMVP },
        ];
      },
    },
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  @use 'sass:map';
  @use 'vuetify/settings' as vuetify;
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-player-card {
    $this: &;

    width: 320px;

    &:not(:last-child) {
      margin-right: 25px;
    }

    &--disabled {
      opacity: 0.5;
    }

    &__image {
      height: 200px;

      @include mixins.media(sm) {
        height: 240px;
      }

      &--team {
        background-repeat: no-repeat;
        background-position: center;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          backdrop-filter: blur(10px) grayscale(80%);
          background-color: rgba(map-get(vuetify.$grey, 'lighten-2'), 0.7);
        }
      }
    }

    &__header {
      background: map.get(vuetify.$grey, 'lighten-3');

      .v-card-title {
        font-size: variables.$font-size--default;
        line-height: variables.$line-height--default !important; // stylelint-disable-line
      }

      .v-card-subtitle {
        font-size: variables.$font-size--small;
        line-height: variables.$line-height--default !important; // stylelint-disable-line
        padding: 0 !important; // stylelint-disable-line
      }
    }

    &__stat-item {
      .v-list-item-title {
        font-size: variables.$font-size--small;
        line-height: variables.$line-height--default;
      }

      .v-list-item-subtitle {
        font-size: variables.$font-size--xs;
        line-height: variables.$line-height--default;
      }
    }

    &__fallback-text {
      font-size: variables.$font-size--small;
      padding: 20px;
      text-align: center;
    }
  }
</style>
