import { TeamSeasonConfig } from '@/types/leauge-config';

export const Season202324: TeamSeasonConfig = {
  divisionId: 13687,
  standings: [
    {
      divisionId: 13687,
      playoffCut: 4,
    },
  ],
  playoffId: 14511,
  teamId: 6740,
  seasonLabel: '2023-24',
};

export default Season202324;
