import { TeamSeasonConfig } from '@/types/leauge-config';

export const Season202223: TeamSeasonConfig = {
  divisionId: 11004,
  standings: [
    {
      divisionId: 11004,
      playoffCut: 4,
    },
  ],
  playoffId: 11932,
  teamId: 34457,
  seasonLabel: '2022-23',
};

export default Season202223;
