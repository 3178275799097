<template>
  <v-select
    :class="b('')"
    :density="density"
    bind="$attrs"
  >
  </v-select>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { Density, V_FORM_FIELD_DENSITY } from '@/setup/globals';

  // type Setup = {}
  type Data = {
    density: Density;
  };

  /**
   * A wrapper component for vuetify v-select.
   *
   * @see https://vuetifyjs.com/en/components/selects/#usage
   */
  export default defineComponent({
    name: 'e-select',

    // components: {},

    // props: {},
    // emits: [],

    // setup(): Setup {},
    data(): Data {
      return {
        density: V_FORM_FIELD_DENSITY,
      };
    },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    // methods: {},
    // render() {},
  });
</script>

<style lang="scss">
  // @use 'sass:map';
  @use 'vuetify/settings' as vuetify;
  // @use '@/setup/scss/mixins';
  // @use '@/setup/scss/variables';

  .e-select {
    $this: &;

    .v-field {
      font-size: vuetify.$font-size-root;
    }

    input {
      border: 0;
      background: none;
    }
  }
</style>
