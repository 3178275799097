<template>
  <div :class="b()">
    <div :class="b('selects')">
      <e-select
        v-model="sessionStore.activeTeam"
        label="Team"
        :class="b('teams')"
        :items="sessionStore.getTeams"
        item-title="name"
        item-value="id"
        return-object
        hide-details
        @update:model-value="onChangeTeam"
      ></e-select>
      <e-select
        v-model="sessionStore.activeSeason"
        label="Season"
        :class="b('seasons')"
        :items="sessionStore.activeTeam.seasons"
        item-title="seasonLabel"
        item-value="seasonLabel"
        return-object
        hide-details
        @update:model-value="onChangeSeason"
      ></e-select>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import useSessionStore from '@/stores/session';
  import eSelect from '@/elements/e-select.vue';
  import { TeamConfig, TeamSeasonConfig } from '@/types/leauge-config';

  type Setup = {
    sessionStore: ReturnType<typeof useSessionStore>;
  };

  // type Data = {};

  /**
   *
   */
  export default defineComponent({
    name: 'c-team-season-selectors',
    components: { eSelect },

    // components: {},

    // props: {},
    // emits: [],

    setup(): Setup {
      return {
        sessionStore: useSessionStore(),
      };
    },
    // data(): Data {
    //   return {
    //   };
    // },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      onChangeTeam(team: TeamConfig) {
        const currentSeasonExists = team.seasons.find(
          (season) => season.seasonLabel === this.sessionStore.activeSeason.seasonLabel
        );

        if (currentSeasonExists) {
          this.sessionStore.activeSeason = currentSeasonExists;
        } else {
          this.sessionStore.activeSeason = team.seasons[0] as TeamSeasonConfig;
        }

        this.$router.push({
          params: {
            team: team.name,
            season: this.sessionStore.activeSeason.seasonLabel,
          },
        });
      },

      onChangeSeason(season: TeamSeasonConfig) {
        this.$router.push({
          params: {
            team: this.sessionStore.activeTeam.name,
            season: season.seasonLabel,
          },
        });
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use 'sass:map';
  @use 'vuetify/settings' as vuetify;
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-team-season-selectors {
    $this: &;

    &__selects {
      display: flex;
      gap: 12px;
    }
  }
</style>
