<template>
  <div :class="b()">
    <v-sparkline
      :auto-line-width="chart.autoLineWidth"
      :fill="chart.fill"
      :gradient="chart.gradient"
      :gradient-direction="chart.gradientDirection"
      :line-width="chart.width"
      :model-value="chart.value"
      :padding="chart.padding"
      :smooth="chart.smooth"
      :stroke-linecap="chart.lineCap"
      :type="chart.type"
      auto-draw
    />

    <v-slide-group
      :class="b('content-slider')"
      show-arrows
    >
      <v-timeline direction="horizontal">
        <v-timeline-item
          v-for="game in team.games"
          :key="game.id"
          :dot-color="game.gameVictoryState === 'W' ? 'green' : 'red'"
          :class="b('timeline-item')"
        >
          <template #icon>
            <c-team-logo
              :team-id="game.opponentTeamId"
              :class="b('team-logo')"
              link-to-team-page
            />
          </template>
          <span :class="b('score')">
            {{ game.score }}
          </span>
        </v-timeline-item>
      </v-timeline>
    </v-slide-group>

    <v-slide-group
      :class="b('content-slider')"
      show-arrows
    >
      <v-card :class="b('stat-card')">
        <v-card-item
          title="Powerplay"
          subtitle="Statistik in Überzahl"
        >
        </v-card-item>

        <v-card-text>
          <span class="text-h3"> {{ team.teamStats.powerplayPercentage }} % </span>
        </v-card-text>

        <v-list class="bg-transparent">
          <v-list-item
            v-for="(stat, index) in powerplayStats"
            :key="`pp-${index}`"
            density="compact"
          >
            <v-list-item-title>{{ stat.value }}</v-list-item-title>
            <v-list-item-subtitle> {{ stat.label }}</v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-card>

      <v-card :class="b('stat-card')">
        <v-card-item
          title="Penaltykilling"
          subtitle="Statistik in Unterzahl"
        >
        </v-card-item>

        <v-card-text>
          <span class="text-h3"> {{ team.teamStats.penaltyKillingPercentage }} % </span>
        </v-card-text>

        <v-list class="bg-transparent">
          <v-list-item
            v-for="(stat, index) in penaltykillStats"
            :key="`pp-${index}`"
            density="compact"
          >
            <v-list-item-title>{{ stat.value }}</v-list-item-title>
            <v-list-item-subtitle> {{ stat.label }}</v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-card>

      <v-card :class="b('stat-card')">
        <v-card-item
          title="Strafen"
          subtitle="Übersicht der Stafen"
        >
        </v-card-item>

        <v-card-text>
          <span class="text-h3"> {{ team.teamStats.penaltyMinutes }} min </span>
        </v-card-text>

        <v-list class="bg-transparent">
          <v-list-item
            v-for="(stat, index) in penaltyStats"
            :key="`pp-${index}`"
            density="compact"
          >
            <v-list-item-title>{{ stat.value }}</v-list-item-title>
            <v-list-item-subtitle> {{ stat.label }}</v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-card>
    </v-slide-group>
  </div>
</template>

<script lang="ts">
  import { PropType, defineComponent } from 'vue';
  import cTeamLogo from '@/components/c-team-logo.vue';
  import { TeamDetails } from '@/types/team-details';

  // type Setup = {};

  // type Setup = {};
  type Data = {
    chart: {
      width: number;
      smooth: boolean;
      padding: number;
      lineCap: string;
      gradient: string[];
      value: number[];
      gradientDirection: 'top' | 'bottom' | 'left' | 'right' | undefined;
      fill: boolean;
      type: 'bar' | 'trend' | undefined;
      autoLineWidth: boolean;
    };
  };

  /**
   *
   */
  export default defineComponent({
    name: 'c-team-stats',
    components: {
      cTeamLogo,
    },

    // components: {},

    props: {
      /**
       * The Team data.
       */
      team: {
        type: Object as PropType<TeamDetails>,
        default: () => {},
      },
    },
    // emits: [],

    // setup(): Setup {
    //   return {
    //   };
    // },
    data(): Data {
      return {
        chart: {
          width: 2,
          smooth: true,
          padding: 8,
          lineCap: 'round',
          gradient: ['lime', 'grey', 'red'],
          value: [],
          gradientDirection: 'top',
          fill: false,
          type: 'trend',
          autoLineWidth: false,
        },
      };
    },

    computed: {
      powerplayStats(): Array<{ label: string; value: string | number }> {
        const powerplay = this.team.teamStats?.powerplay?.split('/');

        return [
          { label: 'Spiele', value: this.team.teamStats.gamesPlayed },
          { label: 'Powerplays gespiel', value: powerplay?.[1] || 0 },
          { label: 'Tore geschossen', value: powerplay?.[0] || 0 },
          { label: 'Shorthander kassiert', value: this.team.teamStats.shorthandedGoalsAgainst },
        ];
      },

      penaltykillStats(): Array<{ label: string; value: string | number }> {
        const penaltykill = this.team.teamStats?.penaltykill?.split('/');

        return [
          { label: 'Spiele', value: this.team.teamStats.gamesPlayed },
          { label: 'in Unterzahl', value: penaltykill?.[1] || 0 },
          { label: 'Tore kassiert', value: penaltykill?.[0] || 0 },
          { label: 'Shorthander geschossen', value: this.team.teamStats.shorthandedGoalsFor },
        ];
      },

      penaltyStats(): Array<{ label: string; value: string | number }> {
        return [
          { label: '2min', value: this.team.teamStats.minorPenalties },
          { label: '>2min', value: this.team.teamStats.majorPenalties },
          { label: '10min', value: this.team.teamStats.misconducts },
          { label: 'Match / Spiel', value: this.team.teamStats.gameMisconducts + this.team.teamStats.matchPenalties },
        ];
      },
    },
    watch: {
      team: {
        handler() {
          if (this.team) {
            this.chart.value = this.getChartValue();
          }
        },
        immediate: true,
      },
    },

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeUnmount() {},
    // unmounted() {},

    methods: {
      getChartValue(): number[] {
        const values: number[] = [];
        let winningStreak = 0;

        if (!this.team) {
          return [];
        }

        this.team.games.forEach((game) => {
          if (game.gameVictoryState === 'W') {
            winningStreak += 1;
          } else {
            winningStreak -= 1;
          }

          values.push(winningStreak);
        });

        return values;
      },
    },
    // render() {},
  });
</script>

<style lang="scss">
  @use 'sass:map';
  @use 'vuetify/settings' as vuetify;
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-team-stats {
    $this: &;

    &__timeline-item {
      .v-timeline-divider__inner-dot {
        padding: 5px;
      }

      &:nth-child(odd) {
        .v-timeline-item__body {
          padding-block-end: 10px !important; // stylelint-disable-line
        }
      }

      &:nth-child(2n) {
        .v-timeline-item__body {
          padding-block-start: 10px !important; // stylelint-disable-line
        }
      }
    }

    &__team-logo {
      width: 40px;
      filter: grayscale(100%);
    }

    &__score {
      font-family: variables.$font-family-condensed;
    }

    &__content-slider {
      margin-bottom: 40px;

      .v-slide-group__content {
        padding: 20px 2px;
      }

      .v-slide-group__prev,
      .v-slide-group__next {
        @include mixins.media($down: sm) {
          min-width: 20px;
        }
      }
    }

    &__stat-card {
      width: 330px;
      margin-right: 30px;
    }
  }
</style>
