<template>
  <v-card
    :class="b('')"
    class="mx-auto"
    variant="flat"
    :color="color"
    :max-width="maxWidth"
  >
    <template #title>
      {{ title }}
    </template>
    <template #text>
      <div :class="b('image')">
        <img
          v-if="variant === 'zamboni'"
          src="../assets/icons/i-zamboni.svg"
          alt="naba-hockey-data-error-img"
          loading="lazy"
          width="70"
        />
        <img
          v-else
          src="../assets/icons/i-hockey-sticks.svg"
          alt="naba-hockey-data-error-img"
          loading="lazy"
          width="70"
        />
      </div>

      {{ message }}
    </template>
  </v-card>
</template>

<script lang="ts">
  export default {
    name: 'c-error-panel',
    // components: {},
    // mixins: [],

    props: {
      /**
       * The headline of the error panel
       */
      title: {
        type: String,
        default: 'Heiliger Hockeyschläger',
      },

      /**
       * The message of the error panel
       */
      message: {
        type: String,
        default: 'Ein Fehler ist aufgetreten',
      },

      /**
       * The variant of the error panel
       */
      variant: {
        type: String,
        default: 'default',
        validator(value: string) {
          return ['default', 'zamboni'].includes(value);
        },
      },

      /**
       * Define the background color.
       */
      color: {
        type: String,
        default: 'grey-lighten-4',
      },

      /**
       * Define the max width.
       */
      maxWidth: {
        type: Number,
        default: 600,
      },
    },
    // data() {
    //   return {};
    // },

    // computed: {},
    // watch: {},

    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // activated() {},
    // deactivated() {},
    // beforeDestroy() {},
    // destroyed() {},

    // methods: {},
    // render() {},
  };
</script>

<style lang="scss">
  @use '@/setup/scss/mixins';
  @use '@/setup/scss/variables';

  .c-error-panel {
    padding: 20px;
    font-family: variables.$font-family-default-condensed;
    text-align: center;

    &__image {
      width: 65px;
      margin: auto auto 20px;

      img {
        width: 100%;
      }
    }
  }
</style>
